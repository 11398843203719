<template>
  <div class="forgetContainer">
    <div :class="bodySize?'forgetContainers':'forgetContainersMax'">
      <div class="forget_left">
        <h2 style="margin-top:30px">忘记密码</h2>
        <div class="form_item">
          <el-form :model="form" ref="form" label-width="70px" :hide-required-asterisk="true">
            <el-form-item
              prop="mobile"
              label="手机号"
              :rules="[{ required: true, message: '请输入您的手机号', trigger: 'blur' },{ pattern:/^(?:(?:\+|00)86)?1[3-9]\d{9}$/,message: '请输入正确的手机号格式',trigger: 'blur'}]"
            >
              <el-input v-model="form.mobile" placeholder="请输入您的手机号" ></el-input>
            </el-form-item>

            <el-form-item
              prop="code"
              label="验证码"
              :rules="{ required: true, message: '请输入您的验证码', trigger: 'blur' }"
            >
              <el-input v-model="form.code" placeholder="请输入您的验证码">
                <template slot="append">
                  <el-button style="color:#5e72f9;width:110px" @click="getCode">{{btnText}}</el-button>
                </template>
              </el-input>
            </el-form-item>

            <el-form-item
              prop="password"
              label="密码"
              :rules="[{ required: true, message: '请输入您的密码', trigger: 'blur' },{min:6,max:10,message:'密码长度在6到15个字符'}]"
            >
              <el-input
                v-model="form.password"
                placeholder="6-15位必须包含数字和字母"
                type="password"
                show-password
              ></el-input>
            </el-form-item>
            <el-form-item
              prop="password"
              label="确认密码"
              :rules="[{ required: true, message: '请输入您的密码', trigger: 'blur' },{min:6,max:10,message:'密码长度在6到15个字符'}]"
            >
              <el-input
                v-model="form.newpassword"
                placeholder="6-15位必须包含数字和字母"
                type="password"
                show-password
              ></el-input>
            </el-form-item>

            <el-form-item>
              <Slide v-on:confirmSuccess="dragBtn"></Slide>
            </el-form-item>

            <el-form-item>
              <el-button type="primary" @click="onSubmit" style="width:100%;height:50px">保存</el-button>
              <div class="form_link">
                <el-link :underline="false" href="/#/login">返回登录</el-link>
                <el-link :underline="false" @click="toRegister">免费注册</el-link>
              </div>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <div class="forget_right" v-show="!bodySize">
        <div>
          <img src="../assets/logo.png" width="90" height="90" />
        </div>
        <div>
          <img src="../assets/login/logn_r_middle.png" height="90" />
        </div>
        <div class="img_r_bottom">
          <img src="../assets/login/酒店1.png" width="60" />
          <img src="../assets/login/酒店2.png" width="60" />
          <img src="../assets/login/酒店3.png" width="60" />
          <img src="../assets/login/酒店4.png" width="60" />
          <img src="../assets/login/酒店5.png" width="60" />
          <img src="../assets/login/酒店6.png" width="60" />
          <img src="../assets/login/酒店7.png" width="60" />
          <img src="../assets/login/酒店8.png" width="60" />
        </div>
        <div class="forget_r_bottom">
          <el-link :underline="false" href="https://www.housky.cn/" target="_blank">
            查看更多案例
            <i class="el-icon-arrow-right"></i>
          </el-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Slide from "../components/Slide";
import { getForgetPassWordCodeApi, editForgetPassWordApi } from "../api/data";
export default {
  components: {
    Slide
  },
  data() {
    return {
      form: {
        mobile: "",
        code: "",
        password: "",
        newpassword: ""
      },
      confirmSuccess: false,
      btnText: "获取验证码",
      timer: null,
      bodySize: false
    };
  },
  created() {
    // this.wathBtn();
    this.wathSize();
  },
  methods: {
    //监听键盘enter事件
    wathBtn() {
      document.addEventListener("keydown", () => {
        let key = window.event.keyCode;
        if (key == 13) this.onSubmit();
      });
    },
    wathSize() {
      if (document.body.clientWidth <= 992) {
        this.bodySize = true;
      } else {
        this.bodySize = false;
      }
      window.addEventListener("resize", () => {
        //宽度小于1190收缩左侧菜单栏
        if (document.body.clientWidth <= 992) {
          this.bodySize = true;
        } else {
          this.bodySize = false;
        }
      });
    },

    //提交密码
    onSubmit() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          if (this.confirmSuccess) {
            if (this.form.password == this.form.newpassword) {
              editForgetPassWordApi(this.form).then(res => {
                if (res) {
                  this.$message.success(res.msg);
                  this.$router.push("/login");
                }
              });
            }else{
               this.$message.warning("两次密码不一致");
            return false;
            }
          } else {
            this.$message.warning("请向右滑动");
            return false;
          }
        } else {
          return false;
        }
      });
    },
    //滑动完成事件
    dragBtn(val) {
      this.confirmSuccess = val;
    },

    //发送验证码
    getCode() {
      if (this.form.mobile) {
        if (this.timer == null) {
          getForgetPassWordCodeApi({ mobile: this.form.mobile }).then(res => {
            if (res) this.$message.success(res.msg);
          });
        }
      } else {
        this.$message.warning("请输入您的手机号");
        return false;
      }

      const TIME_COUNT = 60;
      if (!this.timer) {
        this.btnText = TIME_COUNT;
        this.timer = setInterval(() => {
          if (this.btnText > 0 && this.btnText <= TIME_COUNT) {
            this.btnText--;
          } else {
            clearInterval(this.timer);
            this.timer = null;
            this.btnText = "获取验证码";
          }
        }, 1000);
      }
    },
       toRegister(){
      this.$router.push("/register")
    },
  }
};
</script>
<style lang="scss">
.forgetContainer {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  max-width: 100%;
  background: url("../assets/login/login_home.jpg") center no-repeat;
  background-size: cover;
  text-align: center;
  position: relative;
}

.forgetContainers {
  width: 500px;
  height: 500px;
  margin: auto;
  display: flex;
  .forget_left {
    border-radius: 10px;
  }
}
.forgetContainersMax {
  width: 1000px;
  height: 500px;
  margin: auto;
  display: flex;
}
.forget_left {
  background: #ffffff;
  width: 100%;
  height: 100%;
  border-radius: 10px 0 0 10px;
  .form_item {
    margin: 20px 40px 0px 20px;
    .form_link {
      display: flex;
      justify-content: space-between;
      .el-link {
        font-size: 12px;
      }
    }
    // .el-input__inner {
    //   height: 50px;
    //   line-height: 50px;
    // }
  }
}
.forget_right {
  background: rgba($color: #38466f, $alpha: 0.8);
  width: 100%;
  height: 100%;
  border-radius: 0 10px 10px 0;
  padding-top: 10px;
  .img_r_bottom {
    margin: 20px 20px 0 60px;
    img {
      margin: 10px 40px 0 0;
    }
  }
  .forget_r_bottom {
    padding-top: 50px;
    .el-link {
      color: #ffffff;
      font-size: 12px;
    }
    .el-link:hover {
      color: #5e72f9;
    }
  }
}
</style>